$(document).ready( function() {
    $('.wysiwyg').ckeditor( {
        toolbarGroups: [
            {"name":"basicstyles","groups":["basicstyles"]},
            {"name":"paragraph","groups":["list","blocks"]}
        ],
        // Remove the redundant buttons from toolbar groups defined above.
        removeButtons: 'Strike,Subscript,Superscript,Anchor,Styles,Specialchar'
    } );
    $('.wysiwyg-admin').ckeditor( {
        toolbarGroups: [
            {"name":"basicstyles","groups":["basicstyles"]},
            {"name":"paragraph","groups":["list","blocks"]},
            {"name":"links","groups":["link","unlink"]}
        ],
        removeButtons: 'Strike,Subscript,Superscript,Anchor,Styles,Specialchar'
    } );
} );