$(function() {
    $('.modal')
        .on('show.bs.modal', function (event) {
            var $button = $(event.relatedTarget),
                title = $button.data('modal-title'),
                modal = $(this);

            if( title && 0 < title.length ) {
                modal.find('.modal-title').text(title);
            }
        })
        .on( 'hidden.bs.modal', function(event) {
            $(this).find('.modal-body').empty();
            $(this).removeData('bs.modal');
        } );

        $('.modal form').submit( function(e) {
            e.preventDefault();
            var url = $(this).attr('action');
            var $form = $(this);
            $('.help-block.error', this).hide();
            $('.form-group', this).removeClass('has-error');

            $.ajax({
                type: "POST",
                url: url, //process to mail
                data: $(this).serialize(),
                success: function(msg){
                    if( 'OK' == msg ) {
                        $form.unbind('submit').submit()
                    } else {
                        alert('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie die Webentwicklung.');
                    }
                },
                error: function( jqXHR ) {
                    var response = jqXHR.responseText;
                    if( response.length > 0 ) {
                        var json = $.parseJSON( response );
                        $.each( json, function( name, messages ) {
                            var $formGroup = $('[name="'+name+'"]').closest( 'div.form-group' );
                            var $helpBlock = $('.help-block.error', $formGroup);
                            $formGroup.addClass('has-error');
                            $helpBlock
                                .empty()
                                .append( '<strong>'+messages[0]+'</strong>' )
                                .slideDown(400);
                        } );
                    } else {
                        alert('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie die Webentwicklung.');
                    }
                }
            });

            return false;
        } );

} );