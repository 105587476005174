$(function() {
    $('.jobtoggle').click( function(e) {
        e.preventDefault();
        var $container = $(this).siblings('div.details').first();
        if( $container.is(':visible') ) {
            $container.slideUp();
            $(this).text('weiterlesen »');
        } else {
            $container.slideDown();
            $(this).text('« zuklappen');
        }
    } );
});