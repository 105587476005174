$(function() {
    $.datepicker.setDefaults( $.datepicker.regional[ "de" ] );
    $( ".datepicker" ).datepicker({
        changeMonth: true,
        changeYear: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        minDate: "-60y",
        maxDate: "+6m",
        yearRange: "-60:+1"
    });
    $( ".datepicker").each( function() {
        var minDate = $(this).data('min-date') ? $(this).data('min-date') : "-60y";
        var maxDate = $(this).data('max-date') ? $(this).data('max-date') : "+6m";

        $(this).datepicker( 'option', 'minDate', minDate );
        $(this).datepicker( 'option', 'maxDate', maxDate );
    } );

    $( ".datetimepicker" ).datetimepicker({
        changeMonth: true,
        changeYear: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        minDate: "-60y",
        maxDate: "+6m",
        yearRange: "-60:+1",
        timeFormat : "HH:mm",
        showMillisec: false,
        showTimezone: false,
        showMicrosec: false,
        timeInput: true
    });
    $.timepicker.regional['de'] = {
        currentText: 'Jetzt',
        closeText: 'Schließen',
        amNames: ['AM', 'A'],
        pmNames: ['PM', 'P'],
        timeFormat: 'HH:mm',
        timeSuffix: '',
        timeOnlyTitle: 'Uhrzeit wählen',
        timeText: 'Uhrzeit:',
        hourText: 'Stunde',
        minuteText: 'Minute',
        secondText: 'Sekunde',
        millisecText: 'Millisekunde',
        microsecText: 'Microsekunde',
        timezoneText: 'Zeitzone',
        isRTL: false
    };
    $.timepicker.setDefaults($.timepicker.regional['de']);
});



/* German initialisation for the jQuery UI date picker plugin. */
/* Written by Milian Wolff (mail@milianw.de). */
( function( factory ) {
    if ( typeof define === "function" && define.amd ) {

        // AMD. Register as an anonymous module.
        define( [ "../widgets/datepicker" ], factory );
    } else {

        // Browser globals
        factory( jQuery.datepicker );
    }
}( function( datepicker ) {

    datepicker.regional.de = {
        closeText: "Schließen",
        prevText: "&#x3C;Zurück",
        nextText: "Vor&#x3E;",
        currentText: "Heute",
        monthNames: [ "Januar","Februar","März","April","Mai","Juni",
            "Juli","August","September","Oktober","November","Dezember" ],
        monthNamesShort: [ "Jan","Feb","Mär","Apr","Mai","Jun",
            "Jul","Aug","Sep","Okt","Nov","Dez" ],
        dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
        dayNamesShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
        dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
        weekHeader: "KW",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "" };
    datepicker.setDefaults( datepicker.regional.de );

    return datepicker.regional.de;

} ) );